import React, { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { HomeContainer, HomeInnerContainer } from "../style/Home/Home.module";
import { TopMenu } from "../component/home/TopMenu";
import { RecommendSection } from "../component/home/Section/RecommendSection";
import styled from "styled-components";
import { MainCategorySection } from "../component/home/Section/MainCategorySection";
import { MainAdv } from "../component/home/Adv/MainAdv";
import BottomAd from "../component/ad-banner/BottomAd";
import { Footer } from "../component/common/Footer";
import { useNavigate } from "react-router-dom";
import { FloatingButton } from "../component/home/HomeFloatingButton";
import { WhiskySpecialSection } from "../component/home/Section/WhiskySpecialSection";
import { HomeBanner } from "../component/home/HomeBanner";
import { SubAdv } from "../component/home/Adv/SubAdv";
// import { Underline } from "../component/home/Underline";

export const HomeV4 = () => {
  const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
  `;

  const AdContainer = styled.div`
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;

    @media (max-width: 400px) {
      min-width: 360px; /* 400px 미만인 경우 */
      width: 100%; /* 400px 이상인 경우 */
    }
  `;

  const Underline = styled.div`
    width: 96%;
    height: 1px;
    background-color: #eeeeee; // 매우 연한 회색
    margin-bottom: 20px;
  `;

  //kakao share
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://developers.kakao.com/sdk/js/kakao.js";
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  const shareBtn = () => {
    // alert('카카오 공유')
    if (window.Kakao) {
      if (!window.Kakao.isInitialized()) {
        const kakao = window.Kakao;
        kakao.init("bd6295d7c2426350afac958e9355f2c6");
      }

      const kakao = window.Kakao;

      const shareLink = "https://dutyfree-price.com/";
      // 카카오 링크 공유 설정
      kakao.Link.sendDefault({
        objectType: "feed", // 카카오 링크 공유 여러 type들 중 feed라는 타입
        content: {
          title: "국내 면세점 최저가", // 인자값으로 받은 title
          description: '"깜짝 놀랄 가격!! 세상에 이 가격이 맞아!! "', // 인자값으로 받은 title
          imageUrl:
            "https://dfx-img.s3.ap-northeast-2.amazonaws.com/dfp_share_banner.png",
          link: {
            mobileWebUrl: shareLink, // 위에서 설정된 공유 링크 사용
            webUrl: shareLink,
          },
        },
        buttons: [
          {
            title: "면세점 가격비교 템 쏜다",
            link: {
              mobileWebUrl: shareLink, // 위에서 설정된 공유 링크 사용
              webUrl: shareLink,
            },
          },
        ],
      });
    } else {
      console.log("kakao 없음");
    }
  };

  // 디코딩해서 id를 확인합니다
  let isTest = false;
  try {
    const decodedToken = jwtDecode(localStorage.getItem("accessToken"));
    isTest = decodedToken.id === 251 || decodedToken.id === 277;
  } catch (error) {
    console.error("토큰 디코딩 오류:", error);
  }

  const navigate = useNavigate();
  useEffect(() => {
    // Session Storage에 companyId가 있는지 확인하여 리다이렉트
    const companyId = sessionStorage.getItem("companyId");

    if (companyId && companyId != 1) {
      navigate("/whisky365?companyId=" + companyId);
    }
  }, []);

  // AiaFloatingButtonContainer 클릭시 로그인 상태면 /aiappCoupon, 로그아웃 상태면 /login으로 이동
  const onClickAiaFloatingButton = () => {
    // 로그인 상태면 (accessToken 으로 체크한다.)
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      navigate("/eventCoupon");
    } else {
      // alert("로그인 후 이용해주세요.");
      navigate("/event1");
    }
  };

  useEffect(() => {
    const generateSessionId = () => {
      const characters = "0123456789";

      return Array.from(
        { length: 5 },
        () => characters[Math.floor(Math.random() * characters.length)]
      ).join("");
    };

    const getSessionId = () => {
      let sessionId = sessionStorage.getItem("sessionId");

      if (!sessionId) {
        sessionId = generateSessionId();
        sessionStorage.setItem("sessionId", sessionId);
      }

      return sessionId;
    };

    getSessionId();
  }, []);

  return (
    <HomeContainer>
      <CenterContainer>
        <HomeInnerContainer>
          <TopMenu></TopMenu>
          <HomeBanner> </HomeBanner>

          <MainAdv></MainAdv>
          <br />

          <WhiskySpecialSection></WhiskySpecialSection>

          <RecommendSection></RecommendSection>
          {/* 가격비교  */}
          <MainCategorySection></MainCategorySection>
          <SubAdv></SubAdv>
        </HomeInnerContainer>
        <AdContainer>
          <BottomAd></BottomAd>
        </AdContainer>
        <Footer></Footer>

        <FloatingButton
          bottom="125px"
          onClick={() => shareBtn()}
          imgSrc="https://dfx-img.s3.ap-northeast-2.amazonaws.com/dfp_share.png"
        />
        <FloatingButton
          bottom="65px"
          onClick={() => onClickAiaFloatingButton()}
          imgSrc="https://dfx-img.s3.ap-northeast-2.amazonaws.com/dfp_coupon.png"
        />
        <FloatingButton
          bottom="5px"
          link="http://pf.kakao.com/_RjKPG"
          imgSrc="https://dfx-img.s3.ap-northeast-2.amazonaws.com/kakaodfp.png"
        />
      </CenterContainer>
    </HomeContainer>
  );
};
