import styled from "styled-components";

export const Layout = styled.div`
  position: relative;
`;

export const Container = styled.div`
  position: relative;
  margin-top: 12px;
  margin-bottom: -18px;
`;

export const TitleContainer = styled.div`
  div {
    padding-left: 20px;
    font-size: 24px;
    font-weight: 800;
  }
`;
