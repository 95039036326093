import { AppBarMenu } from "../../style/BottonAppBar.module.js";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import {
  MenuContainer,
  MenuItem,
  Footer,
  MenuItemUnderlined,
  SnsContainer,
  FloatingButtonContainer,
} from "./DrawerMenu.module.js";
import {
  Layout,
  Title,
  ContentItem,
  CopyrightItem,
} from "../../style/Footer.module.js";

export const DrawerMenu = ({ open, setDrawerOpen }) => {
  const navigate = useNavigate();

  const accessToken = localStorage.getItem("accessToken");

  const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.your-domain.com; secure`;
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    deleteCookie("accessToken");
    deleteCookie("refreshToken");
    window.location.reload();
  };

  return (
    <Drawer anchor="right" open={open} onClose={() => setDrawerOpen(false)}>
      <MenuContainer>
        <img
          src={"https://dfx-front.s3.ap-northeast-2.amazonaws.com/logo2.png"}
          alt="로고 이미지"
          style={{
            width: "180px",
            maxWidth: "90%",
            maxHeight: "70%",
            height: "auto",
            marginBottom: "50px",
          }}
          onClick={() => {
            navigate("/");
          }}
        />
        <MenuItem
          onClick={() => {
            navigate("/search");
          }}
        >
          검색
        </MenuItem>
        {/* <MenuItemUnderlined></MenuItemUnderlined> */}
        {/* <MenuItem>
                    면세점 쿠폰
                </MenuItem> */}
        {/* <MenuItem onClick={() => {navigate('/coupon')}}>
                    공항 쿠폰
                </MenuItem> */}
        {/* <MenuItem>
                    여행정보
                </MenuItem> */}
        <MenuItemUnderlined></MenuItemUnderlined>
        <MenuItem
          onClick={() => {
            navigate("/agreement/1");
          }}
        >
          이용약관
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/agreement/2");
          }}
        >
          개인정보처리방침
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/company");
          }}
        >
          회사소개
        </MenuItem>
        <MenuItem>
          <div style={{ marginRight: "15px" }}>App 다운로드</div>

          <div style={{ marginRight: "5px" }}>
            <a
              target="_blank"
              href="https://apps.apple.com/kr/app/%EB%A9%B4%EC%84%B8%EA%B0%80%EA%B2%A9%EB%B9%84%EA%B5%90/id6472394872"
              rel="noreferrer"
            >
              <img
                src={
                  "https://dfx-front.s3.ap-northeast-2.amazonaws.com/as.webp"
                }
                alt="로고 이미지"
                style={{ width: "40px", height: "40px", height: "auto" }}
              />
            </a>
          </div>

          <div style={{ marginRight: "5px" }}>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.dfpr.apps"
              rel="noreferrer"
            >
              <img
                src={"https://dfx-img.s3.ap-northeast-2.amazonaws.com/gp.png"}
                alt="로고 이미지"
                style={{ width: "37px", height: "37px", height: "auto" }}
              />
            </a>
          </div>
        </MenuItem>
        {accessToken && <MenuItem onClick={handleLogout}>로그아웃</MenuItem>}
        <MenuItem></MenuItem>
        <MenuItem></MenuItem>
        <MenuItem></MenuItem>
        <SnsContainer>
          {/* {} */}
          <div>
            <a target="_blank" href="https://dfpr.kr/" rel="noreferrer">
              <img
                src={
                  "https://dfx-img.s3.ap-northeast-2.amazonaws.com/icon-%E1%84%92%E1%85%A9%E1%86%B7%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5.png"
                }
                alt="로고 이미지"
                style={{ width: "40px", height: "40px", height: "auto" }}
              />
            </a>
          </div>
          <div>
            <a
              target="_blank"
              href="https://www.instagram.com/dutyfree_price"
              rel="noreferrer"
            >
              <img
                src={
                  "https://dfx-img.s3.ap-northeast-2.amazonaws.com/icon-%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%89%E1%85%B3%E1%84%90%E1%85%A1%E1%84%80%E1%85%B3%E1%84%85%E1%85%A2%E1%86%B7.png"
                }
                alt="로고 이미지"
                style={{ width: "40px", height: "40px", height: "auto" }}
              />
            </a>
          </div>
          <div>
            <a
              target="_blank"
              href="https://blog.naver.com/dutyfreeprice2023"
              rel="noreferrer"
            >
              <img
                src={
                  "https://dfx-img.s3.ap-northeast-2.amazonaws.com/icon-%E1%84%87%E1%85%B3%E1%86%AF%E1%84%85%E1%85%A9%E1%84%80%E1%85%B3.png"
                }
                alt="로고 이미지"
                style={{ width: "40px", height: "40px", height: "auto" }}
              />
            </a>
          </div>
          <div>
            {/* <a href = "https://blog.naver.com/dutyfreeprice2023"> */}
            <img
              src={
                "https://dfx-img.s3.ap-northeast-2.amazonaws.com/icon-%E1%84%8B%E1%85%B2%E1%84%90%E1%85%B2%E1%84%87%E1%85%B3.png"
              }
              alt="로고 이미지"
              style={{ width: "40px", height: "40px", height: "auto" }}
            />
            {/* </a> */}
          </div>
        </SnsContainer>
        <Footer style={{ position: "relative", bottom: 0 }}>
          <Title>(주)디에프피알</Title>
          <ContentItem>대표이사 : 이창원</ContentItem>
          <ContentItem>사업자등록번호 : 408-86-17825</ContentItem>
          <ContentItem>연락처 : betheone@dfpr.kr</ContentItem>
          <ContentItem>
            주소 : 서울특별시 마포구 독막로 9길 18, 3층 B2호 (서교동)
          </ContentItem>
          <CopyrightItem>
            © Copyright 2023 Dutyfreeprice. All Rights Reserved.
          </CopyrightItem>
        </Footer>
      </MenuContainer>
    </Drawer>
  );
};
