import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import {
  Layout,
  Container,
  TitleContainer,
  Underline,
} from "../../../style/Home/Section/WhiskySpecialSection.module";
import { client } from "../../../client";
import { GET_WHISKY_PRODUCTS } from "../gql/queries";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const CategoryTags = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px 0;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Tag = styled.div`
  padding: 8px 16px;
  background-color: ${(props) => (props.selected ? "#6741ff" : "#f5f5f5")};
  color: ${(props) => (props.selected ? "white" : "#1a1a1a")};
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 16px;
`;

const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  background: white;
  border-radius: 8px;
  overflow: hidden;
`;

const ProductImage = styled.img`
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  background-color: #f5f5f5;
`;

const ProductInfo = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
`;

const ProductName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #1a1a1a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const ProductBrand = styled.div`
  font-size: 13px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: keep-all;
  line-height: 1.2;
  height: 1.2em;
  margin-bottom: 2px;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 1;
  margin-top: 4px;
`;

const Discount = styled.span`
  color: #ff3b3b;
  font-size: 14px;
  font-weight: bold;
`;

const Price = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const WonPrice = styled.span`
  color: #666;
  font-size: 13px;
  line-height: 1;
  margin: 0;
`;

const popupAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.3) translateY(-100px);
  }
  20% {
    opacity: 1;
    transform: scale(1.1) translateY(0);
  }
  40% {
    transform: scale(0.9);
  }
  60% {
    transform: scale(1.03);
  }
  80% {
    transform: scale(0.97);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(0.3) translateY(100px);
  }
`;

const swingAnimation = keyframes`
  0% { 
    transform: translate(-50%, -50%) rotate(0deg) scale(0.3);
    opacity: 0;
  }
  20% { 
    transform: translate(-50%, -50%) rotate(0deg) scale(1.1);
    opacity: 1;
  }
  30% { 
    transform: translate(-50%, -50%) rotate(-10deg) scale(1);
  }
  50% { 
    transform: translate(-50%, -50%) rotate(10deg) scale(1);
  }
  70% { 
    transform: translate(-50%, -50%) rotate(-10deg) scale(1);
  }
  85% { 
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
    opacity: 1;
  }
  100% { 
    transform: translate(-50%, -50%) rotate(0deg) scale(0.3);
    opacity: 0;
  }
`;

const PopupImage = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  animation: ${swingAnimation} 2s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    forwards;

  img {
    max-width: 300px;
    height: auto;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.2));
  }
`;

const SubTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 4px 0px;
  border-radius: 20px;
  transition: background-color 1s ease;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

const SubTitle = styled.div`
  font-size: 16px;
  color: #666;
`;

const CustomerText = styled.div`
  font-size: 16px;
  color: #666;
`;

const ArrowIcon = styled.span`
  position: relative;
  top: -4px;

  &::after {
    content: ">";
    font-size: 12px;
    color: #66660;
    display: inline-block;
    transform: scaleY(2);
    margin-right: 16px;
  }
`;

export const WhiskySpecialSection = () => {
  const navigate = useNavigate();
  const categories = ["스카치", "버번", "일본/대만", "중국", "기타"];
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [products, setProducts] = useState([]);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.04,
    rootMargin: "0px",
    // triggerOnce: true,
  });

  const logos = Array(5).fill("https://example.com/lotte-duty-free-logo.png"); // 실제 로고 URL로 교체 필요

  const categoryMapping = {
    스카치: "SCOTCH",
    버번: "BOURBON",
    "일본/대만": "JAPAN",
    중국: "CHINA",
    기타: "ETC",
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data } = await client.query({
          query: GET_WHISKY_PRODUCTS,
          fetchPolicy: "no-cache",
        });

        if (data && data.getWhiskyProductsForHomeSection) {
          setProducts(data.getWhiskyProductsForHomeSection);
        }
      } catch (error) {
        console.error("Error fetching whisky products:", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const getExchangeRateFromCookie = () => {
      const cookies = document.cookie
        .split("; ")
        .find((row) => row.startsWith("exchangeRate="));
      return cookies ? cookies.split("=")[1] : null;
    };

    const exchangeRateFromCookie = getExchangeRateFromCookie();
    if (exchangeRateFromCookie) {
      setExchangeRate(exchangeRateFromCookie);
    }
  }, []);

  useEffect(() => {
    if (inView) {
      const now = new Date();
      const lastSeenTime = localStorage.getItem("whiskySpecialLastSeen");

      // 마지막으로 본 시간이 오늘이 아니면 팝업을 보여줌
      const shouldShowPopup = () => {
        if (!lastSeenTime) return true;
        const lastDate = new Date(parseInt(lastSeenTime));
        return lastDate.getDate() !== now.getDate();
      };

      if (shouldShowPopup()) {
        setShowPopup(true);
        localStorage.setItem("whiskySpecialLastSeen", now.getTime().toString());

        // 테스트를 위해 10초 후에 팝업 닫기
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
      }
    }
  }, [inView]);

  const filteredProducts = products.filter(
    (product) => product.home_category === categoryMapping[selectedCategory]
  );

  const calculateDiscount = (normal, member) => {
    const normalPrice = parseFloat(normal);
    const memberPrice = parseFloat(member);
    if (normalPrice && memberPrice) {
      const discount = ((normalPrice - memberPrice) / normalPrice) * 100;
      return Math.round(discount) + "%";
    }
    return "0%";
  };

  const handleProductClick = (productId) => {
    navigate(`/whisky365/${productId}`);
  };

  return (
    <Layout>
      <Container>
        <Underline></Underline>
        {/* <TitleWrapper> */}
        <TitleContainer>면세점 위스키 특별 할인 판매</TitleContainer>
        <SubTextContainer
          onClick={() =>
            window.open("https://pf.kakao.com/_RjKPG/chat", "_blank")
          }
        >
          <TextContent>
            <SubTitle>부산면세점, 신라아이파크와 함께합니다</SubTitle>
            <CustomerText>24시간 고객센터 바로가기</CustomerText>
          </TextContent>
          <ArrowIcon />
        </SubTextContainer>

        {inView && showPopup && (
          <PopupImage>
            <img
              src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/popup_hotdeal.png"
              alt="특가 판매 팝업"
            />
          </PopupImage>
        )}

        <CategoryTags>
          {categories.map((category) => (
            <Tag
              key={category}
              selected={selectedCategory === category}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </Tag>
          ))}
        </CategoryTags>

        <ProductGrid ref={ref}>
          {filteredProducts.map((product) => (
            <ProductCard
              key={product.id}
              onClick={() => handleProductClick(product.id)}
            >
              <ProductImage
                src={product.thumbnail_img_url || product.img_url}
                alt={product.name}
              />
              <ProductInfo>
                <ProductName>{product.name}</ProductName>
                <ProductBrand>{product.name_eng}</ProductBrand>
                <PriceContainer>
                  <Discount>
                    {calculateDiscount(
                      product.price_normal,
                      product.price_member
                    )}
                  </Discount>
                  <Price>${product.price_member}</Price>
                </PriceContainer>
                <WonPrice>
                  (
                  {parseInt(
                    product.price_member * exchangeRate
                  ).toLocaleString()}
                  원)
                </WonPrice>
              </ProductInfo>
            </ProductCard>
          ))}
        </ProductGrid>
      </Container>
    </Layout>
  );
};
