import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  justify-content: center;
  height: 46px;
  padding-top: 10px;
  padding-bottom: 25px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 46px;
  width: 100%;
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 90%;
  padding: 15px;
  border-radius: 25px;
  border: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  outline: none;

  &::placeholder {
    color: #9e9e9e;
  }

  &:focus {
    border-color: #d3d3d3;
  }
`;

export const ResultsContainer = styled.div`
  position: abolute;
  top: 10px;
  left: 10px;
  background-color: white; /* 하얀색 배경 */
  padding: 10px; /* 원하는 여백 */
`;
