import styled from "styled-components";

export const Layout = styled.div`
  position: relative;
`;

export const Container = styled.div`
  position: relative;
  margin-left: 20px;
`;

export const TitleContainer = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

export const Underline = styled.div`
  width: 94%;
  height: 1px;
  background-color: #eeeeee; // 매우 연한 회색
  margin-bottom: 20px;
`;
