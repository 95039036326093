import {
  AdBanner,
  AdvContainer,
  AdvWrapper,
  Layout,
} from "../../../style/Home/RecommendSection.module";
import React, { useEffect, useState, useRef } from "react";
import Carousel from "react-material-ui-carousel";
import { client } from "../../../client";
import { BannerClicked } from "./Ad.module";
import { GET_AD_BANNER } from "../../admin-ad/Ad.gql";

const LogType = {
  AD: "AD",
  GENERAL_LOG: "GENERAL_LOG",
  TEST: "TEST",
};

export const MainAdv = () => {
  const boxRef = useRef([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const { data } = await client.query({
          query: GET_AD_BANNER,
          variables: {
            visible: true,
          },
        });

        const tempAds = data.getAdBannerList
          .filter((ad) => ad.account_id === 10) // accountId가 1인 요소로 필터링
          .map((ad, idx) => {
            return {
              ...ad,
              key: idx,
            };
          });

        console.log("items......", items);

        setItems(tempAds);
      } catch (error) {
        console.error("Error fetching ads:", error);
      }
    };

    fetchAds();
  }, []);

  // // View Log
  const [bannerIndex, setBannerIndex] = useState(items[0]?.key);

  const handleCarouselChange = (index) => {
    setBannerIndex(index);
  };

  return (
    <Layout>
      <AdvContainer>
        <Carousel
          interval={5000}
          onChange={(index) => handleCarouselChange(index)}
        >
          {items.map((item, i) => (
            <AdvWrapper key={i} item={item}>
              <AdBanner
                key={item.key}
                src={item.image_url}
                alt={item.name}
                onClick={() => {
                  BannerClicked(item);

                  if (item.url_link && !item.url_link.includes("whisky365")) {
                    window.location.href = item.url_link;
                    return;
                  }

                  try {
                    const currentUrl = new URL(window.location.href);
                    const companyId = parseInt(
                      currentUrl.searchParams.get("companyId"),
                      10
                    );

                    const targetPath =
                      companyId === 10
                        ? "/whisky365?companyId=10"
                        : "/whisky365";

                    window.location.href = `${window.location.origin}${targetPath}`;
                  } catch (error) {
                    console.error("Invalid URL:", window.location.href);
                    window.location.pathname = "/whisky365";
                  }
                }}
                ref={(ref) => (boxRef.current[i] = ref)}
              />
            </AdvWrapper>
          ))}
        </Carousel>
      </AdvContainer>
    </Layout>
  );
};
