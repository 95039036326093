import { useLocation, useNavigate } from "react-router";

import MyPageImg from "./img/gnb-my.svg";
import BackArrow from "./img/angle-left 1.png";
const HomeImage =
  "https://dfx-img.s3.ap-northeast-2.amazonaws.com/whisky_home.png";

export const WhiskyLayout = ({
  children,
  pageName,
  menu,
  iframe,
  setIframeUrl,
  companyId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleMypageBtn = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken)
      return navigate("/loginbsdf", { state: { from: location } });

    navigate("/mypage");
  };

  const handleBackBtn = () => {
    if (iframe !== null) {
      setIframeUrl(null);
    } else {
      navigate(-1);
    }
  };

  const handleGoHomeBtm = () => {
    navigate("/");
  };

  console.log("companhyId...", companyId);

  return (
    <main className="font-CustomSans flex flex-col justify-center items-center bg-gray-50 h-full w-full sm:min-w-[360px]">
      <div className="relative bg-white w-full max-w-400 min-h-[60px] shadow-custom sm:min-w-[360px] sm:w-screen">
        <header className="flex flex-col justify-between w-full pl-3 pr-3 border-b-2">
          <nav
            aria-label="header"
            className="flex items-center justify-between h-[60px] mt-2 w-full pr-0"
          >
            <div className="w-full flex justify-between items-center h-[60]">
              <div className="w-1/3 flex justify-start pl-4">
                <button onClick={handleBackBtn}>
                  <img
                    src={BackArrow}
                    alt="뒤로가기"
                    className="w-[20px] h-[20px]"
                  />
                </button>
              </div>
              <div className="w-1/3 flex justify-center">
                <h1 className="text-lg font-bold">상품정보</h1>
              </div>
              <div className="w-1/3 flex justify-end items-center gap-2 pr-2">
                {menu === true && companyId === 1 && (
                  <button onClick={handleGoHomeBtm}>
                    <img
                      src={HomeImage}
                      alt="홈으로"
                      className="w-[24px] h-[24px]"
                    />
                  </button>
                )}
                {menu === true && (
                  <button onClick={handleMypageBtn}>
                    <img
                      src={MyPageImg}
                      alt="메뉴"
                      className="w-[24px] h-[24px]"
                    />
                  </button>
                )}
              </div>
            </div>
          </nav>
        </header>

        {children}
      </div>
    </main>
  );
};
